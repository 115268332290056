@use 'sass:list';
@use 'sass:map';
@use './fs-fonts';
@use './typefaces';

@mixin output-typeface-tokens-as-css-vars {
  --fsn-typeface-headline: #{typefaces.$headline-font};
  --fsn-typeface-body: #{typefaces.$body-font};
  --fsn-typeface-monospace: #{typefaces.$monospace-font};
}

@function _is-defined($value) {
  @return $value != inherit and $value != null;
}

@function _shorthand($level) {
  // In shorthand form, font-variant can only be 'normal' or 'small-caps'. Similarly, font-stretch can not contain multiple values. To avoid confusion, we never include them: the explicit prop should be used instead.

  $font-style: map.get($level, font-style);
  $font-weight: map.get($level, font-weight);
  $font-size: map.get($level, font-size);
  $line-height: map.get($level, line-height);
  $font-family: map.get($level, font-family);

  @if (not _is-defined($font-size) or not _is-defined($font-family)) {
    @return null;
  }

  $size: $font-size;
  @if (_is-defined($line-height)) {
    $size: list.slash($font-size, $line-height);
  }

  $value: ();
  @if (_is-defined($font-style)) {
    $value: list.append($value, $font-style);
  }
  @if (_is-defined($font-weight)) {
    $value: list.append($value, $font-weight);
  }
  $value: list.append($value, $size);
  $value: list.append($value, $font-family);
  @return $value;
}

@mixin _emit($prefix, $level, $prop-name) {
  $value: map.get($level, $prop-name);
  @if ($value) {
    #{$prefix}-#{$prop-name}: #{$value};
  }
}

@mixin _level($prefix, $level) {
  @if (_shorthand($level)) {
    #{$prefix}-font: #{_shorthand($level)};
  }
  @include _emit($prefix, $level, font-family);
  @include _emit($prefix, $level, font-size);
  @include _emit($prefix, $level, line-height);
  @include _emit($prefix, $level, font-weight);
  @include _emit($prefix, $level, font-style);
  @include _emit($prefix, $level, font-variant);
}

@mixin output-typography-tokens-as-css-vars($theme) {
  $config: fs-fonts.fs-fonts(
    $scale: 1,
  );
  // Headline
  @include _level(--fsn-type-headline-large, map.get($config, 'Headline/Large'));
  @include _level(--fsn-type-headline-medium, map.get($config, 'Headline/Medium'));
  @include _level(--fsn-type-headline-small, map.get($config, 'Headline/Small'));
  @include _level(--fsn-type-headline-x-small, map.get($config, 'Headline/X-Small'));
  // Body
  @include _level(--fsn-type-body-large, map.get($config, 'Body/Large'));
  @include _level(--fsn-type-body-medium, map.get($config, 'Body/Medium'));
  @include _level(--fsn-type-body-small, map.get($config, 'Body/Small'));
  @include _level(--fsn-type-body-tiny, map.get($config, 'Body/Tiny'));
  @include _level(--fsn-type-body-aside, map.get($config, 'Body/Aside'));
  // Data
  @include _level(--fsn-type-data-large, map.get($config, 'Data/Large'));
  @include _level(--fsn-type-data-medium, map.get($config, 'Data/Medium'));
  @include _level(--fsn-type-data-small, map.get($config, 'Data/Small'));
  @include _level(--fsn-type-data-small-alt, map.get($config, 'Data/Small Alt'));
  @include _level(--fsn-type-data-label, map.get($config, 'Data/Label'));
}
