@use 'sass:map';
@use './shadow';

@mixin output-theme-css-vars($theme) {
  $color-theme: map.get($theme, color);

  --fsn-elevation-shadow-light: #{shadow.shadow-light(black)};
  --fsn-elevation-shadow-heavy: #{shadow.shadow-heavy(black)};

  --fsn-elevation-shadow-field: #{shadow.shadow-field(map.get($color-theme, primary-surface))};
  --fsn-elevation-shadow-field-semi: #{shadow.shadow-field-semi(map.get($color-theme, accent-stroke))};
  --fsn-elevation-shadow-field-active: #{shadow.shadow-field-active(map.get($color-theme, accent-stroke))};
}

@mixin override-theme-css-vars($partial-theme) {
  $color-theme: map.get($partial-theme, color);
  @if ($color-theme != null) {
    --fsn-elevation-shadow-field: #{shadow.shadow-field(map.get($color-theme, primary-surface))};
    --fsn-elevation-shadow-field-semi: #{shadow.shadow-field-semi(map.get($color-theme, accent-stroke))};
    --fsn-elevation-shadow-field-active: #{shadow.shadow-field-active(map.get($color-theme, accent-stroke))};
  }
}
