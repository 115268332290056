@use 'a.theme' as a;
@use 'aside.theme' as aside;
@forward 'a.theme' as a-* show a-theme, a-color;
@forward 'aside.theme' as aside-* show aside-theme, aside-color;

/*
 * These mixins allow us to use the same theming mechanism for native tags as we
 * do for custom components.
 */

// @mixin all-colors($theme) {
//   @include aside.color($theme);
// }

// @mixin all-typography($theme) {
//   // @include aside.typography($theme);
// }

// @mixin all-themes($theme) {
//   @include all-colors($theme);
//   @include all-typography($theme);
// }

/**
 * Emit styles for all tags. These refer to the variables defined in the *-theme
 * and *-color mixins.
 */
@mixin all-components($cls, $prefix) {
  @include a.component($cls, $prefix);
  @include aside.component($cls, $prefix);
}
