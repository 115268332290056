@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    --fsn-comp-stepper-selected-color: #{mat.get-theme-color($theme, primary, 700)};
    --fsn-comp-stepper-unselected-color: #{mat.get-theme-color($theme, primary, 500)};
  }
}

@mixin theme($theme) {
  @include color($theme);
}
