// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved

@use '@angular/material' as mat;

@use '../theming/elevation';
@use '../theming/typography';
@use './material.theme' as themes;
@use './mat-dialog.theme' as mat-dialog;
@import 'variables-new.scss';

@mixin core {
  @include mat.core();
}

@mixin all-component-themes($theme) {
  // TODO: List out only the components that we actually use.
  // https://v15.material.angular.io/guide/theming
  @include mat.all-component-themes(themes.$main);
}

// Scoped themes for special parts of the app.
@mixin all-color($theme) {
  @include mat.icon-color($theme);
  @include mat.button-color($theme);
}

// TODO: Break this down into a separate file per-component to make it more maintainable.
@mixin overrides {
  /*
   * NOTE
   * Everything below should be approached with care. Stop current approach
   * of overriding angular material styles explicitly, until we get to using
   * angular material theming fully and properly; and as recommended by angular.
   * The approach below is strongly discouraged by angular material.
   */
  // TODO: It seems that many of the style definitions below would be better done
  // by using Material's official theming system, as used above. Explore how to do
  // properly, so that we're working with the library, not against it.
  //
  // "Angular strongly discourages, and does not directly support, overriding
  // component CSS [...] Component DOM structure and CSS classes are considered
  // private implementation details that may change at any time.
  // https://material.angular.io/guide/theming#style-customization-outside-the-theming-system

  // WORKAROUND:  Material 17 autocomplete component. Dropdown panel background
  // color is no longer black as before (defaults incorrectly to white)
  // In Material 15, this was controlled by `--mdc-theme-surface`.
  :root {
    --mat-autocomplete-background-color: #{$primary-color};

    .mat-mdc-icon-button.mat-mdc-button-base {
      --mdc-icon-button-state-layer-size: 40px;
      padding: 9px;
    }
  }

  // Form Field
  :root {
    // .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    //   --mat-mdc-form-field-label-transform: translateY(-50px) scale(1);
    // }

    .mat-mdc-form-field {
      line-height: 20px;
      position: relative;
      label {
        position: absolute;
        top: -25px;
        left: -15px;
        @include typography.typography-level(typography.$fs-fonts, 'Body/Small', bold);
      }
    }

    .mat-mdc-form-field-infix {
      min-height: auto;
      width: 100%;
    }

    .mat-mdc-form-field-text-prefix {
      margin-right: 5px;
    }
    .mat-mdc-form-field-text-suffix {
      margin-left: 5px;
    }
    // .mat-mdc-floating-label {
    // }
  }

  // Mat 15
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  // Mat 17
  :root {
    --mat-form-field-container-vertical-padding: 10px;
    --mat-form-field-container-height: 40px;

    .mat-mdc-form-field-text-suffix,
    .mat-mdc-form-field-text-prefix {
      color: $text-light;
    }
  }

  /// Dropdown

  //Material 15
  :root {
    // FIXME: This sets the background colour of dropdowns etc, but it is much too
    // broad: it overrides the color of text that is NOT on a primary background,
    // which then requires further workarounds to correct.
    --mdc-theme-surface: #{$primary-color};
    --mdc-theme-text-primary-on-background: #{$primary-color-text};

    .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
      color: $primary-color-text;
    }
    .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal:after {
      color: $primary-color-text;
    }
    .mat-mdc-option:hover:not(.mdc-list-item--disabled),
    .mat-mdc-option:focus:not(.mdc-list-item--disabled),
    .mat-mdc-option.mat-mdc-option-active,
    .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
      background: $text-dark;
    }
    .mdc-list-item--disabled .mdc-list-item__primary-text,
    .mdc-list-item--disabled .mdc-list-item__secondary-text,
    .mdc-list-item--disabled .mdc-list-item__overline-text,
    .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
    .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
    .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
      color: $grey3;
    }
    .mat-divider {
      border-top-color: $text-dark;
    }
  }

  //Dropdown Material 17
  :root {
    --mat-menu-item-label-text-color: #{$primary-color-text};
    --mat-select-panel-background-color: #{$primary-color};

    --mat-divider-color: #{$text-dark};
    --mat-menu-container-color: #{$primary-color};
    .mat-primary {
      --mat-minimal-pseudo-checkbox-selected-checkmark-color: #{$primary-color-text};
    }
    .mat-accent {
      --mat-option-label-text-color: #{$primary-color-text};
      --mat-optgroup-label-text-color: #{$primary-color-text};

      --mat-option-hover-state-layer-color: #{$text-dark};
      --mat-option-focus-state-layer-color: #{$text-dark};
      --mat-option-selected-state-layer-color: #{$text-dark};
      --mat-option-selected-state-label-text-color: #{$primary-color-text};
      --mat-minimal-pseudo-checkbox-selected-checkmark-color: #{$primary-color-text};
    }
  }

  //// Tooltips
  :root {
    --mdc-plain-tooltip-supporting-text-size: 13px;
    --mdc-plain-tooltip-container-shape: 0px;
  }
  /// Mat 15
  .mat-mdc-tooltip {
    --mdc-plain-tooltip-container-color: #{$primary-color};
  }

  /// Mat 17
  :root {
    --mdc-plain-tooltip-container-color: #{$primary-color};
    .mdc-tooltip {
      overflow: visible;
    }
    .mdc-tooltip__surface {
      padding: 20px;
    }
  }

  .mat-mdc-tooltip-panel {
    $arrow-size: 5px;
    .mdc-tooltip {
      background-color: #{$primary-color};
    }

    &.mat-mdc-tooltip-panel-below,
    &.mat-mdc-tooltip-panel-above,
    &.mat-mdc-tooltip-panel-left,
    &.mat-mdc-tooltip-panel-right {
      .mdc-tooltip::after {
        content: '';
        position: absolute;
        border-width: $arrow-size;
        border-style: solid;
      }
    }

    &.mat-mdc-tooltip-panel-below {
      .mdc-tooltip::after {
        top: (-$arrow-size * 2);
        left: calc(50% - #{$arrow-size});
        border-color: transparent transparent $primary-color transparent;
      }
    }

    &.mat-mdc-tooltip-panel-above {
      .mdc-tooltip::after {
        top: 100%;
        left: calc(50% - #{$arrow-size});
        border-color: $primary-color transparent transparent transparent;
      }
    }

    &.mat-mdc-tooltip-panel-left {
      .mdc-tooltip::after {
        top: calc(50% - #{$arrow-size});
        left: 100%;
        border-color: transparent transparent transparent $primary-color;
      }
    }

    &.mat-mdc-tooltip-panel-right {
      .mdc-tooltip::after {
        top: calc(50% - #{$arrow-size});
        left: (-$arrow-size * 2);
        border-color: transparent $primary-color transparent transparent;
      }
    }
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused {
    box-shadow: $input-shadow;
    border-bottom-left-radius: var(--mdc-shape-small, 4px);
    border-bottom-right-radius: var(--mdc-shape-small, 4px);
  }

  ////Overlay Pannels
  .cdk-overlay-container {
    // Display over the old modal. When we stop using that one, we can probably remove this.
    z-index: 2000;
  }
  .cdk-overlay-pane {
    margin: 10px 0;
  }

  //// Dropdown
  :root div.mat-mdc-select-panel {
    box-shadow: var(--fsn-elevation-shadow-heavy);
    padding: 10px 0;
    max-height: 300px;

    border-radius: 0;
  }
  :root {
    .mat-mdc-option {
      padding: 10px 20px;

      .mdc-list-item__primary-text {
        min-width: 0;
      }
    }

    .mat-mdc-select {
      @include typography.typography-level(typography.$fs-fonts, 'Body/Small');
    }
  }

  .mat-mdc-select-arrow-wrapper {
    height: 20px !important;
  }

  /// Scrollbar
  .mdc-menu-surface,
  .mat-mdc-menu-panel {
    &::-webkit-scrollbar {
      width: 10px;
    }
    $scroll-color: $primary-color;

    &::-webkit-scrollbar-track {
      background-color: $scroll-color;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $text-light;
      border: 2px solid $scroll-color;
      border-radius: 10px;
    }
  }

  ////Buttons
  // Mat 15
  .mat-mdc-button.mat-mdc-button-base,
  .mat-mdc-raised-button.mat-mdc-button-base,
  .mat-mdc-unelevated-button.mat-mdc-button-base,
  .mat-mdc-outlined-button.mat-mdc-button-base {
    height: auto;
  }

  .mat-mdc-outlined-button:not(:disabled) {
    border: none;
    box-shadow:
      0px 1px 0px 0px rgba(black, 0.15),
      0px 0px 0px 1px rgba(black, 0.1);
  }

  // Mat 17
  :root {
    // FIXME: This seems to make it difficult to correctly size buttons in a flexbox.
    --mdc-text-button-container-height: auto;
    --mdc-filled-button-container-height: auto;
    --mdc-outlined-button-container-height: auto;
    --mdc-protected-button-container-height: auto;

    .mdc-button {
      min-width: 0;
      padding: 15px 20px;
      --mdc-outlined-button-label-text-size: 14px;
      --mat-select-trigger-text-font: typography.$body-font;

      &.mat-mdc-unelevated-button {
        border: 1px solid var(--mdc-filled-button-container-color);
      }

      &.mat-mdc-button.mat-mdc-button-base {
        text-decoration: underline;
      }

      .mdc-button__label {
        white-space: nowrap;
      }

      &[size='tiny'] {
        &.mat-mdc-unelevated-button,
        &.mat-mdc-outlined-button {
          padding: 8px;
          font-size: 12px;
        }
      }

      &[size='small'] {
        &.mat-mdc-unelevated-button,
        &.mat-mdc-outlined-button {
          height: 40px;
          padding: 10px;
          font-size: 13px;
        }
      }

      &[size='large'] {
        &.mat-mdc-unelevated-button,
        &.mat-mdc-outlined-button {
          padding: 20px 30px;
        }
      }
    }

    button[mat-icon-button] {
      &[color='grey'] {
        background-color: $button-grey;
      }
      &[color='black'] {
        color: $primary-color-text;
        background-color: $primary-color;
      }
    }
  }

  //Datepicker
  //Mat 15
  .mat-datepicker-toggle .mat-mdc-icon-button {
    width: 40px;
    height: 40px;
    font-size: 20px;
    padding: 9px;
  }
  .mat-datepicker-content {
    background-color: $primary-color;
    color: $primary-color-text;

    .mat-datepicker-toggle,
    .mat-calendar-next-button,
    .mat-calendar-previous-button,
    .mat-calendar-table-header,
    .mat-calendar-body-label {
      color: $primary-color-text;
    }

    .mat-calendar-arrow {
      fill: $primary-color-text;
    }

    .mat-calendar-body-cell-content {
      color: $primary-color-text;
    }

    .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
      border-color: $primary-color-text;
    }

    &.mat-accent {
      .mat-calendar-body-selected,
      .mat-calendar-body-in-range:before {
        background: $text-light;
      }

      .mat-calendar-body-cell:not(.mat-calendar-body-disabled) {
        &.mat-calendar-body-active,
        &:hover {
          > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
              .mat-calendar-body-comparison-identical
            ) {
            background-color: $text-dark;
          }
        }
      }
    }
  }

  //Mat 17
  :root {
    --mat-datepicker-calendar-container-background-color: #{$primary-color};
    --mat-datepicker-calendar-container-text-color: #{$primary-color-text};
    --mat-datepicker-calendar-date-text-color: #{$primary-color-text};
    --mat-datepicker-calendar-period-button-icon-color: #{$primary-color-text};
    --mat-datepicker-calendar-navigation-button-icon-color: #{$primary-color-text};
    --mat-datepicker-calendar-header-text-color: #{$primary-color-text};
    --mat-datepicker-calendar-body-label-text-color: #{$primary-color-text};
    --mat-datepicker-calendar-date-today-outline-color: #{$primary-color-text};
    --mat-datepicker-calendar-header-divider-color: #{$primary-color-text};

    .mat-calendar-header .mat-mdc-button:not(:disabled) {
      color: $primary-color-text;
    }
    .mat-datepicker-content.mat-accent {
      border-radius: 0;
      --mat-datepicker-calendar-date-selected-state-background-color: #{$text-light};
      --mat-datepicker-calendar-date-in-range-state-background-color: #{$text-light};

      --mat-datepicker-calendar-date-selected-state-text-color: #{$primary-color-text};

      --mat-datepicker-calendar-date-focus-state-background-color: #{$text-dark};
      --mat-datepicker-calendar-date-hover-state-background-color: #{$text-dark};
    }

    .mat-calendar-header {
      padding: 0px 8px;
    }

    .mat-datepicker-content .mat-calendar {
      width: auto;
      height: auto;
    }
  }

  ////Radio
  //Mat 15

  //Both

  :root {
    .mat-mdc-radio-button.mat-primary.mat-mdc-radio-checked .mdc-form-field {
      font-weight: bold;
    }

    .mat-mdc-radio-button.mat-primary {
      --mdc-radio-unselected-hover-icon-color: #{$secondary-color};
      --mdc-radio-unselected-focus-icon-color: #{$secondary-color};
      --mdc-theme-text-primary-on-background: $primary;

      font-size: 1rem;
      font-weight: normal;
    }

    .mdc-radio__inner-circle {
      display: none;
    }

    .mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__outer-circle {
      border-width: 7px;
    }

    .mdc-radio .mdc-radio__native-control:enabled:not(:checked) {
      &:hover,
      &:focus {
        ~ .mdc-radio__background .mdc-radio__outer-circle {
          box-shadow: $input-shadow;
        }
      }
    }
  }

  ////Checkbox
  .mat-mdc-checkbox.mat-primary {
    --mdc-checkbox-unselected-focus-icon-color: #{$secondary-color};
    --mdc-checkbox-unselected-hover-icon-color: #{$secondary-color};
    label {
      margin-bottom: 0;
      color: var(--mdc-dialog-supporting-text-color, black);
    }
  }

  .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate) {
    &:hover,
    &:focus {
      ~ .mdc-checkbox__background {
        box-shadow: $input-shadow;
      }
    }
  }

  /// Chips
  .mat-mdc-standard-chip {
    border: 1px solid $border-grey;

    --mdc-chip-elevated-container-color: #{$primary-color-text};
  }

  ///Dialog
  //Mat 15
  :root {
    .mat-mdc-dialog-surface > * > [mat-dialog-close] {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  :root {
    --mdc-dialog-container-shape: 0px;

    .mat-mdc-dialog-container .mdc-dialog__surface {
      padding: 10px 50px;
      min-width: 500px;

      .mat-mdc-dialog-component-host > [mat-dialog-close] {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  // Card
  :root {
    .mat-mdc-card {
      --mdc-elevated-card-container-shape: 0;
      --mdc-elevated-card-container-elevation: none;

      border: 1px solid $border-grey;

      padding: 50px;
    }
  }

  // Icons
  mat-icon {
    svg {
      // Override _reboot.scss
      // In general, this should be baseline - otherwise the icon will be clipped.
      vertical-align: baseline;
    }
  }

  //Progress Bar
  :root {
    --mdc-linear-progress-track-height: 10px;

    .mdc-linear-progress {
      width: 60px;
    }
    .mat-mdc-progress-bar {
      .mdc-linear-progress__bar-inner {
        border-color: currentColor;
        border-top-width: var(--mdc-linear-progress-track-height);
        top: -3px;
      }
      .mdc-linear-progress__buffer-bar {
        background-color: var(--fsn-color-card-borders);
      }
    }
  }

  :root {
    .mat-mdc-slide-toggle.mat-accent {
      --mdc-switch-track-height: 26px;
      --mat-switch-track-outline-color: #e2e2e3;
      --mdc-switch-track-shape: 9999px;
      --mdc-switch-track-width: 50px;

      --mdc-slider-focus-handle-color: white;
      --mdc-slider-hover-handle-color: white;
      --mdc-slider-active-track-color: white;
      --mdc-switch-handle-shape: 9999px;

      --mdc-switch-selected-focus-handle-color: white;
      --mdc-switch-selected-hover-handle-color: white;
      --mdc-switch-selected-handle-color: white;
      --mat-switch-selected-handle-horizontal-margin: 0 0 0 -4px;
      --mdc-switch-selected-track-color: black;
      --mdc-switch-selected-hover-track-color: black;
      --mdc-switch-selected-focus-track-color: black;
      --mat-switch-selected-track-outline-width: 1px;
      --mdc-switch-selected-pressed-track-color: black;
      --mat-switch-selected-handle-size: 20px;

      --mdc-switch-unselected-focus-handle-color: black;
      --mdc-switch-unselected-hover-handle-color: black;
      --mat-switch-unselected-handle-horizontal-margin: 0 0 0 4px;
      --mdc-switch-unselected-handle-color: black;
      --mdc-switch-unselected-track-color: white;
      --mdc-switch-unselected-hover-track-color: white;
      --mdc-switch-unselected-focus-track-color: white;
      --mat-switch-unselected-track-outline-width: 1px;
      --mdc-switch-unselected-pressed-track-color: white;
      --mat-switch-unselected-handle-size: 20px;

      --mdc-switch-handle-elevation-shadow: none;
      --mdc-switch-handle-elevation: none;

      label {
        margin: 0 10px;
      }
    }
  }

  @include mat-dialog.component('.fs-next');
}
