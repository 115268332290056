@use 'sass:map';
@use '@angular/material' as mat;
@use 'fs-next/theming/palette' as palette;
@use 'fs-next/theming/typography' as t;

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
}

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    --fsn-comp-databox-border-color: #{map.get(palette.$foundation-gray, 300)};
  }
}

@mixin theme($theme) {
  @include color($theme);
  @include typography($theme);
}
