@use '@angular/material' as mat;
@use 'fs-next/theming/typography' as typography;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    --fsn-comp-beta-badge-border-color: #{mat.get-theme-color($theme, primary, default)};
    --fsn-comp-beta-badge-text-color: #{mat.get-theme-color($theme, primary, darker)};
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography.emit-vars('fsn-comp-beta-badge', $theme, body-1);
  }
}

@mixin theme($theme) {
  @include color($theme);
  @include typography($theme);
}
