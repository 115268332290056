@use 'sass:map';
@use '../palette' as p;
@use './color.util' as util;

@mixin _prop($name, $value) {
  @if ($value and $value != '') {
    #{$name}: #{$value};
  }
}

@mixin output-theme-css-vars($theme) {
  $color-theme: map.get($theme, color);

  // List these out rather than looping so that they're searchable.
  @include _prop(--fsn-color-page-surface, map.get($color-theme, 'page-surface'));
  @include _prop(--fsn-color-card-surface, map.get($color-theme, 'card-surface'));
  @include _prop(--fsn-color-card-borders, map.get($color-theme, 'card-borders'));
  @include _prop(--fsn-color-text, map.get($color-theme, 'text'));
  @include _prop(--fsn-color-text-light, map.get($color-theme, 'text-light'));
  @include _prop(--fsn-color-text-heavy, map.get($color-theme, 'text-heavy'));

  // Inputs
  @include _prop(--fsn-color-input-surface, map.get($color-theme, 'input-surface'));
  @include _prop(--fsn-color-input-active-surface, map.get($color-theme, 'input-active-surface'));
  @include _prop(--fsn-color-input-active-text, map.get($color-theme, 'input-active-text'));
  @include _prop(--fsn-color-input-text, map.get($color-theme, 'input-text'));
  @include _prop(--fsn-color-input-text-placeholder, map.get($color-theme, 'input-text-placeholder'));
  @include _prop(--fsn-color-input-stroke, map.get($color-theme, 'input-stroke'));
  @include _prop(--fsn-color-input-heavy-stroke, map.get($color-theme, 'input-heavy-stroke'));
  // Buttons
  @include _prop(--fsn-color-primary-surface, map.get($color-theme, 'primary-surface'));
  @include _prop(--fsn-color-primary-text, map.get($color-theme, 'primary-text'));
  @include _prop(--fsn-color-primary-hover-surface, map.get($color-theme, 'primary-hover-surface'));
  @include _prop(--fsn-color-primary-active-surface, map.get($color-theme, 'primary-active-surface'));
  @include _prop(--fsn-color-secondary-surface, map.get($color-theme, 'secondary-surface'));
  @include _prop(--fsn-color-secondary-text, map.get($color-theme, 'secondary-text'));
  @include _prop(--fsn-color-secondary-hover-surface, map.get($color-theme, 'secondary-hover-surface'));
  @include _prop(--fsn-color-secondary-active-surface, map.get($color-theme, 'secondary-active-surface'));
  @include _prop(--fsn-color-accent-surface, map.get($color-theme, 'accent-surface'));
  @include _prop(--fsn-color-accent-stroke, map.get($color-theme, 'accent-stroke'));
  @include _prop(--fsn-color-accent-text, map.get($color-theme, 'accent-text'));
  @include _prop(--fsn-color-disabled-surface, map.get($color-theme, 'disabled-surface'));
  @include _prop(--fsn-color-disabled-text, map.get($color-theme, 'disabled-text'));
  @include _prop(--fsn-color-disabled-stroke, map.get($color-theme, 'disabled-stroke'));

  // Popups etc.
  @include _prop(--fsn-color-elevated-surface, map.get($color-theme, 'elevated-surface'));
  @include _prop(--fsn-color-elevated-text, map.get($color-theme, 'elevated-text'));
  @include _prop(--fsn-color-elevated-input-surface, map.get($color-theme, 'elevated-input-surface'));
  @include _prop(--fsn-color-elevated-input-stroke, map.get($color-theme, 'elevated-input-stroke'));
  @include _prop(--fsn-color-elevated-input-heavy-stroke, map.get($color-theme, 'elevated-input-heavy-stroke'));
  @include _prop(--fsn-color-elevated-button-surface, map.get($color-theme, 'elevated-button-surface'));
  @include _prop(--fsn-color-elevated-button-stroke, map.get($color-theme, 'elevated-button-stroke'));
  @include _prop(--fsn-color-elevated-button-heavy-stroke, map.get($color-theme, 'elevated-button-heavy-stroke'));

  // Special
  @include _prop(--fsn-color-skeleton-surface, map.get($color-theme, 'skeleton-surface'));
}

@mixin override-theme-css-vars($partial-theme) {
  $color-theme: map.get($partial-theme, color);
  @if ($color-theme != null) {
    // Just output tokens that have been defined for this theme
    @each $token, $value in $color-theme {
      --fsn-color-#{$token}: #{$value};
    }
  }
}
