/*
 * These workarounds exist because our former styles relied on Bootstrap,
 * Bootswatch and Reboot, which set CSS with very broad selectors. When we
 * remove those libraries, we can remove these workarounds.
 */

.mat-mdc-radio-group,
.mat-mdc-radio-button,
.mat-mdc-checkbox {
  label {
    // Undo _reboot.scss
    margin-bottom: 0;
  }
}

.mat-mdc-form-field {
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='number'] {
    // Undo bootswatch text underline
    box-shadow: unset;
  }
}
