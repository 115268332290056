@use 'sass:map';
@use '@angular/material' as mat;
@use 'fs-next/theming/palette';

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    --fsn-comp-big-tab-inactive-color: #{map.get(palette.$foundation-gray, 50)};
    --fsn-comp-big-tab-border-color: #{map.get(palette.$foundation-gray, 300)};
    --fsn-comp-big-tab-active-color: #{mat.get-theme-color($theme, accent, 500)};
    --fsn-comp-big-tab-muted-text-color: #{map.get(palette.$foundation-gray, 600)};
  }
}

@mixin theme($theme) {
  @include color($theme);
}
