@use '@angular/material' as mat;

// @mixin typography($theme) {
// }

@mixin color($theme) {
  @if mat.get-color-config($theme) != null {
    --app-aside-color: #{mat.get-theme-color($theme, primary, 'lighter')};
  }
}

@mixin theme($theme) {
  @include color($theme);
  //@include typography($theme);
}

@mixin component($cls, $prefix) {
  #{$cls} aside,
  aside#{$cls},
  .#{$prefix}aside {
    color: var(--app-aside-color);
  }
}
