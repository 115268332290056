@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    --fsn-comp-spinner-selected-color: #{mat.get-theme-color($theme, primary, 900)};
  }
}

@mixin theme($theme) {
  @include color($theme);
}
