@use '@angular/material' as mat;

// @mixin typography($theme) {
// }

@mixin color($theme) {
  @if mat.get-color-config($theme) != null {
    --app-link-color: #{mat.get-theme-color($theme, primary, 'text')};
  }
}

@mixin theme($theme) {
  @include color($theme);
  //@include typography($theme);
}

@mixin component($cls, $prefix) {
  #{$cls} a,
  a#{$cls},
  // Increase specificity by using attribute match, so that link styles tend to override the containers they're placed in.
  .#{$prefix}link[class~='#{$prefix}link'] {
    // :not([href]):not([class]) is a workaround for legacy styles from _reboot.scss
    &,
    &:not([href]):not([class]) {
      color: var(--app-link-color);
    }
  }
}
