@use 'sass:map';
@use './transitions';

@mixin output-animation-tokens-as-css-vars {
  // These are constant.
  --fsn-anim-t-snappy: #{map.get(transitions.$durations, snappy)};
  --fsn-anim-t-chill: #{map.get(transitions.$durations, chill)};
  --fsn-anim-f-soft: #{map.get(transitions.$functions, continuous-soft)};
  --fsn-anim-f-squishy: #{map.get(transitions.$functions, continuous-squishy)};
  --fsn-anim-tf-snappy-soft: #{map.get(transitions.$times-and-functions, snappy-soft)};
  --fsn-anim-tf-snappy-squishy: #{map.get(transitions.$times-and-functions, snappy-squishy)};
  --fsn-anim-tf-chill-soft: #{map.get(transitions.$times-and-functions, chill-soft)};
  --fsn-anim-tf-chill-squishy: #{map.get(transitions.$times-and-functions, chill-squishy)};

  // These variables may change depending on context.
  --fsn-anim-t-color: #{map.get(transitions.$durations, snappy)};
}
