@use 'sass:map';
@use './all.palette' as all;

/** True if the key is a number */
@function _is-tone($key) {
  @if type-of($key) == number {
    @return true;
  }
  @return false;
}

/** Iterate over all tones defined by a palette. */
@mixin _each-tone($palette) {
  @each $tone, $value in $palette {
    @if _is-tone($tone) {
      @content ($tone, $value);
    }
  }
}

/**
 * Export all numbered tones from a palette as CSS variables with a given prefix.
 *
 * For example, given a prefix of 'app-foo', it would output:
 *
 * ```
 * --app-foo-100: #ccc;
 * --app-foo-200: #ddd;
 * // etc.
 * ```
 */
@mixin output-all-tones-as-css-vars($palette, $prefix) {
  @include _each-tone($palette) using ($tone, $value) {
    --#{$prefix}-#{$tone}: #{$value};
  }
}

@mixin output-key-tones-as-css-vars($palette, $prefix) {
  #{$prefix}-100: #{map.get($palette, 100)};
  #{$prefix}-300: #{map.get($palette, 300)};
  #{$prefix}-500: #{map.get($palette, 500)};
  #{$prefix}-700: #{map.get($palette, 700)};
  #{$prefix}-900: #{map.get($palette, 900)};
  #{$prefix}-default: #{map.get($palette, 'default')};
  #{$prefix}-lighter: #{map.get($palette, 'lighter')};
  #{$prefix}-darker: #{map.get($palette, 'darker')};
  #{$prefix}-text: #{map.get($palette, 'text')};
  #{$prefix}-100-contrast: #{map.get($palette, '100-contrast')};
  #{$prefix}-300-contrast: #{map.get($palette, '300-contrast')};
  #{$prefix}-500-contrast: #{map.get($palette, '500-contrast')};
  #{$prefix}-700-contrast: #{map.get($palette, '700-contrast')};
  #{$prefix}-900-contrast: #{map.get($palette, '900-contrast')};
  #{$prefix}-default-contrast: #{map.get($palette, 'default-contrast')};
  #{$prefix}-lighter-contrast: #{map.get($palette, 'lighter-contrast')};
  #{$prefix}-darker-contrast: #{map.get($palette, 'darker-contrast')};
}

@mixin output-all-palettes-as-css-vars {
  // Product
  @include output-key-tones-as-css-vars(all.$biodiversity-blue, $prefix: --fsn-palette-bio-blue);
  @include output-key-tones-as-css-vars(all.$emissions-green, $prefix: --fsn-palette-em-green);
  @include output-key-tones-as-css-vars(all.$forced-labor-orchid, $prefix: --fsn-palette-fl-orchid);

  // Content
  @include output-key-tones-as-css-vars(all.$guidance-blue, $prefix: --fsn-palette-guidance-blue);
  @include output-key-tones-as-css-vars(all.$summary-pink, $prefix: --fsn-palette-summary-pink);
  @include output-key-tones-as-css-vars(all.$meadow, $prefix: --fsn-palette-meadow);

  // Support
  // @include output-key-tones-as-css-vars(all.$warning-orange, $prefix: --fsn-palette-warning-orange);
  // @include output-key-tones-as-css-vars(all.$support-green, $prefix: --fsn-palette-support-green);

  // Special
  @include output-key-tones-as-css-vars(all.$data-indicator, $prefix: --fsn-palette-data-indicator);

  // Recommendation
  @include output-key-tones-as-css-vars(all.$recommendation-aqua, $prefix: --fsn-palette-recommendation-aqua);
  @include output-key-tones-as-css-vars(all.$recommendation-blue, $prefix: --fsn-palette-recommendation-blue);
  @include output-key-tones-as-css-vars(all.$recommendation-cherry, $prefix: --fsn-palette-recommendation-cherry);
}
