// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

/*
 * DEPRECATED
 *
 * Refer to exports from fs-next instead.
 *
 * See https://material.angular.io/guide/theming
 */

$primary-color: black;
$primary-color-text: white;
$secondary-color: #4c2fff;

//TODO: Add some rhyme or reason to all these greys
// Also possibly replace some with black + opacity
$text-dark: #353a3c;
$text-light: #656e72;

$grey3: #757575;

$border-grey: #e2e2e2;
$button-grey: #eeeeee;

$background-grey: #fbfbfb;

$darkest-green: #1f3312;
$dark-green: #065c42;
$green: #51af82;
$light-green: #06d18b;
$lightest-green: #eef8f1;

$input-shadow: 0px 0px 0px 2px rgba($secondary-color, 0.2);
$awesomeShadow:
  0px 0.537px 1.968px 0px rgba(0, 0, 0, 0.03),
  0px 2.363px 4.074px 0px rgba(0, 0, 0, 0.04),
  0px 5.8px 8.125px 0px rgba(0, 0, 0, 0.05),
  0px 11.17px 15.926px 0px rgba(0, 0, 0, 0.07),
  0px 18.796px 29.282px 0px rgba(0, 0, 0, 0.08),
  0px 29px 50px 0px rgba(0, 0, 0, 0.11);

$red: #bf002e;
$orange: #fa5c03;
$yellow: #fecb00;
//$green: #06d18b;
$indicator-grey: $border-grey;

// recommendation colours
$cherry: #ff005c;
$blue: #3c76e0;
$aqua: #15dede;

html {
  --fs-red: #{$red};
  --fs-orange: #{$orange};
  --fs-yellow: #{$yellow};
  --fs-green: #{$green};
  --fs-light-green: #{$light-green};
  --fs-indicator-grey: #{$indicator-grey};
  --fs-cherry: #{$cherry};
  --fs-blue: #{$blue};
  --fs-aqua: #{$aqua};
}

.c-red {
  color: $red;
}
.c-orange {
  color: $orange;
}
.c-yellow {
  color: $yellow;
}
.c-green {
  color: $green;
}

.c-light-green {
  color: var(--fs-light-green);
}
