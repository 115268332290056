@use 'sass:map';
@use '@angular/material' as mat;
@use 'fs-next/theming/palette' as palettes;

@mixin typography($typography-config) {
}

@mixin pill-tab-selected-color($palette) {
  --fsn-pill-comp-tab-selected-color1: #{mat.get-color-from-palette($palette, 'default')};
  --fsn-pill-comp-tab-selected-color2: #{mat.get-color-from-palette($palette, 'default-contrast')};
}

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  fsn-pill-tabs {
    &.app-primary {
      @include pill-tab-selected-color(map.get($color-config, 'primary'));
    }
    &.app-accent {
      @include pill-tab-selected-color(map.get($color-config, 'accent'));
    }
    &.app-warn {
      @include pill-tab-selected-color(map.get($color-config, 'warn'));
    }
    // @if not map.get($color-config, 'is-dark') {
    --fsn-pill-comp-tab-background-color: white;
    // TODO: When we switch to Material 17, we can use the named text colors.
    --fsn-pill-comp-tab-text-color: black;
    --fsn-pill-comp-tab-outline-color: #{map.get(palettes.$foundation-gray, 300)};
    //} @else {
    //}
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
