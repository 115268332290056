@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    --app-zero-emission-background-color: #{mat.get-theme-color($theme, primary, 100)};
    --app-zero-emission-text-color: #{mat.get-theme-color($theme, primary, '100-contrast')};
    --app-zero-emission-heading-color: #{mat.get-theme-color($theme, primary, 'darker')};
  }
}

@mixin theme($theme) {
  @include color($theme);
}
