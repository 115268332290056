@use 'sass:map';
@use '@angular/material' as mat;
@use 'fs-next/theming';

@mixin typography($typography-config) {
  [fsn-squishy-button] {
    font-family: inherit;
    span.arrow {
      font-size: 1.4em;
    }
  }
}

@mixin squishy-color($palette) {
  --fsn-comp-squishy-button-pill-color: #{mat.get-color-from-palette($palette, 'default')};
  --fsn-comp-squishy-button-circle-color: #{mat.get-color-from-palette($palette, 'default-contrast')};
  --fsn-comp-squishy-button-text-color: #{mat.get-color-from-palette($palette, 'default-contrast')};
  --fsn-comp-squishy-button-arrow-color: #{mat.get-color-from-palette($palette, 'default')};
  --fsn-comp-squishy-button-outline-color: #{mat.get-color-from-palette($palette, 'default')};
}

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  [fsn-squishy-button] {
    &.app-primary {
      @include squishy-color(map.get($color-config, 'primary'));
    }
    &.app-accent {
      @include squishy-color(map.get($color-config, 'accent'));
    }
    &.app-warn {
      @include squishy-color(map.get($color-config, 'warn'));
    }
    // @if not map.get($color-config, 'is-dark') {
    &:not(.app-is-done, .app-is-doing).app-disabled {
      --fsn-comp-squishy-button-pill-color: white;
      --fsn-comp-squishy-button-circle-color: #{map.get(theming.$p-foundation-gray, 200)};
      --fsn-comp-squishy-button-text-color: #{map.get(theming.$p-foundation-gray, 600)};
      --fsn-comp-squishy-button-arrow-color: #{map.get(theming.$p-foundation-gray, 600)};
      --fsn-comp-squishy-button-outline-color: #{map.get(theming.$p-foundation-gray, 300)};
    }
    // } @else {
    // }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
