@mixin component($cls) {
  .mat-mdc-dialog-panel#{$cls} {
    margin: 0;
    max-width: 940px;

    .mat-mdc-dialog-surface {
      // Padding around everything in the dialog
      padding: 100px;
      border-radius: 10px;
    }

    .mat-mdc-dialog-component-host {
      // Container for the title, content and actions
      display: flex;
      flex-direction: column;
      gap: 50px;
    }

    .mat-mdc-dialog-title,
    .mat-mdc-dialog-content,
    .mat-mdc-dialog-actions {
      margin: 0;
      padding: 0;
    }

    .mat-mdc-dialog-content {
      // Workaround for Material input fields, which take up more space than they advertise because of the validation error text.
      overflow: visible;
    }

    .mat-mdc-dialog-title::before {
      display: none;
    }
  }
}
