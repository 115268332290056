// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

// Map font faces to files. The browser downloads these selectively when they are needed.

@mixin twk-lausanne-font-faces {
  $weights: (50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750, 800, 850, 900, 950, 1000);

  @each $weight in $weights {
    @font-face {
      font-family: 'TWK Lausanne';
      src: url('/assets/fonts-new/TWKLausanne-#{$weight}.woff2') format('woff2');
      font-weight: #{$weight};
    }
    @font-face {
      font-family: 'TWK Lausanne';
      src: url('/assets/fonts-new/TWKLausanne-#{$weight}Italic.woff2') format('woff2');
      font-weight: #{$weight};
      font-style: italic;
    }
  }
}
