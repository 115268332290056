// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

$flags-images-path: '/assets/images' !default;
.f32 {
  .flag {
    background: url(#{$flags-images-path}/flags32.png) no-repeat;
  }

  ._African_Union,
  ._African_Union_flag {
    background-position: 0 -32px;
  }
  ._Arab_League,
  ._Arab_League_flag {
    background-position: 0 -64px;
  }
  ._ASEAN,
  ._ASEAN_flag {
    background-position: 0 -96px;
  }
  ._CARICOM,
  ._CARICOM_flag {
    background-position: 0 -128px;
  }
  ._CIS,
  ._CIS_flag {
    background-position: 0 -160px;
  }
  ._Commonwealth,
  ._Commonwealth_flag {
    background-position: 0 -192px;
  }
  ._England,
  ._England_flag {
    background-position: 0 -224px;
  }
  ._European_Union,
  ._European_Union_flag {
    background-position: 0 -256px;
  }
  ._Islamic_Conference,
  ._Islamic_Conference_flag {
    background-position: 0 -288px;
  }
  ._Kosovo ._Kosovo_flag {
    background-position: 0 -320px;
  }
  ._NATO,
  ._NATO_flag {
    background-position: 0 -352px;
  }
  ._Northern_Cyprus,
  ._Northern_Cyprus_flag {
    background-position: 0 -384px;
  }
  ._Northern_Ireland,
  ._Northern_Ireland_flag {
    background-position: 0 -416px;
  }
  ._Olimpic_Movement,
  ._Olimpic_Movement_flag {
    background-position: 0 -448px;
  }
  ._OPEC,
  ._OPEC_flag {
    background-position: 0 -480px;
  }
  ._Red_Cross,
  ._Red_Cross_flag {
    background-position: 0 -512px;
  }
  ._Scotland,
  ._Scotland_flag {
    background-position: 0 -544px;
  }
  ._Somaliland,
  ._Somaliland_flag {
    background-position: 0 -576px;
  }
  ._Tibet,
  ._Tibet_flag {
    background-position: 0 -608px;
  }
  ._United_Nations,
  ._United_Nations_flag {
    background-position: 0 -640px;
  }
  ._Wales,
  ._Wales_flag {
    background-position: 0 -672px;
  }

  .eu,
  .eu_flag {
    background-position: 0 -256px;
  }

  .and,
  .and_flag {
    background-position: 0 -704px;
  }
  .are,
  .are_flag {
    background-position: 0 -736px;
  }
  .afg,
  .afg_flag {
    background-position: 0 -768px;
  }
  .atg,
  .atg_flag {
    background-position: 0 -800px;
  }
  .aia,
  .aia_flag {
    background-position: 0 -832px;
  }
  .alb,
  .alb_flag {
    background-position: 0 -864px;
  }
  .arm,
  .arm_flag {
    background-position: 0 -896px;
  }
  .ago,
  .ago_flag {
    background-position: 0 -928px;
  }
  .ata,
  .ata_flag {
    background-position: 0 -960px;
  }
  .arg,
  .arg_flag {
    background-position: 0 -992px;
  }
  .asm,
  .asm_flag {
    background-position: 0 -1024px;
  }
  .aut,
  .aut_flag {
    background-position: 0 -1056px;
  }
  .aus,
  .aus_flag {
    background-position: 0 -1088px;
  }
  .abw,
  .abw_flag {
    background-position: 0 -1120px;
  }
  .ala,
  .ala_flag {
    background-position: 0 -1152px;
  }
  .aze,
  .aze_flag {
    background-position: 0 -1184px;
  }
  .bih,
  .bih_flag {
    background-position: 0 -1216px;
  }
  .brb,
  .brb_flag {
    background-position: 0 -1248px;
  }
  .bgd,
  .bgd_flag {
    background-position: 0 -1280px;
  }
  .bel,
  .bel_flag {
    background-position: 0 -1312px;
  }
  .bfa,
  .bfa_flag {
    background-position: 0 -1344px;
  }
  .bgr,
  .bgr_flag {
    background-position: 0 -1376px;
  }
  .bhr,
  .bhr_flag {
    background-position: 0 -1408px;
  }
  .bdi,
  .bdi_flag {
    background-position: 0 -1440px;
  }
  .ben,
  .ben_flag {
    background-position: 0 -1472px;
  }
  .bmu,
  .bmu_flag {
    background-position: 0 -1504px;
  }
  .brn,
  .brn_flag {
    background-position: 0 -1536px;
  }
  .bol,
  .bol_flag {
    background-position: 0 -1568px;
  }
  .bra,
  .bra_flag {
    background-position: 0 -1600px;
  }
  .bhs,
  .bhs_flag {
    background-position: 0 -1632px;
  }
  .btn,
  .btn_flag {
    background-position: 0 -1664px;
  }
  .bwa,
  .bwa_flag {
    background-position: 0 -1696px;
  }
  .blr,
  .blr_flag {
    background-position: 0 -1728px;
  }
  .blz,
  .blz_flag {
    background-position: 0 -1760px;
  }
  .can,
  .can_flag {
    background-position: 0 -1792px;
  }
  .cod,
  .cod_flag {
    background-position: 0 -1824px;
  }
  .rca,
  .rca_flag {
    background-position: 0 -1856px;
  }
  .cog,
  .cog_flag {
    background-position: 0 -1888px;
  }
  .che,
  .che_flag {
    background-position: 0 -1920px;
  }
  .civ,
  .civ_flag {
    background-position: 0 -1952px;
  }
  .cok,
  .cok_flag {
    background-position: 0 -1984px;
  }
  .chl,
  .chl_flag {
    background-position: 0 -2016px;
  }
  .cmr,
  .cmr_flag {
    background-position: 0 -2048px;
  }
  .chn,
  .chn_flag {
    background-position: 0 -2080px;
  }
  .col,
  .col_flag {
    background-position: 0 -2112px;
  }
  .cri,
  .cri_flag {
    background-position: 0 -2144px;
  }
  .cub,
  .cub_flag {
    background-position: 0 -2176px;
  }
  .cpv,
  .cpv_flag {
    background-position: 0 -2208px;
  }
  .cyp,
  .cyp_flag {
    background-position: 0 -2240px;
  }
  .cze,
  .cze_flag {
    background-position: 0 -2272px;
  }
  .deu,
  .deu_flag {
    background-position: 0 -2304px;
  }
  .dji,
  .dji_flag {
    background-position: 0 -2336px;
  }
  .dnk,
  .dnk_flag {
    background-position: 0 -2368px;
  }
  .dma,
  .dma_flag {
    background-position: 0 -2400px;
  }
  .dom,
  .dom_flag {
    background-position: 0 -2432px;
  }
  .dza,
  .dza_flag {
    background-position: 0 -2464px;
  }
  .ecu,
  .ecu_flag {
    background-position: 0 -2496px;
  }
  .est,
  .est_flag {
    background-position: 0 -2528px;
  }
  .egy,
  .egy_flag {
    background-position: 0 -2560px;
  }
  .esh,
  .esh_flag {
    background-position: 0 -2592px;
  }
  .eri,
  .eri_flag {
    background-position: 0 -2624px;
  }
  .esp,
  .esp_flag {
    background-position: 0 -2656px;
  }
  .eth,
  .eth_flag {
    background-position: 0 -2688px;
  }
  .fin,
  .fin_flag {
    background-position: 0 -2720px;
  }
  .fji,
  .fji_flag {
    background-position: 0 -2752px;
  }
  .fsm,
  .fsm_flag {
    background-position: 0 -2784px;
  }
  .fro,
  .fro_flag {
    background-position: 0 -2816px;
  }
  .fra,
  .fra_flag {
    background-position: 0 -2848px;
  }
  .blm,
  .blm_flag {
    background-position: 0 -2848px;
  }
  .cpt,
  .cpt_flag {
    background-position: 0 -2848px;
  }
  .maf,
  .maf_flag {
    background-position: 0 -2848px;
  }
  .myt,
  .myt_flag {
    background-position: 0 -2848px;
  }
  .gab,
  .gab_flag {
    background-position: 0 -2880px;
  }
  .gbr,
  .gbr_flag {
    background-position: 0 -2912px;
  }
  .shn,
  .shn_flag {
    background-position: 0 -2912px;
  }
  .grd,
  .grd_flag {
    background-position: 0 -2944px;
  }
  .geo,
  .geo_flag {
    background-position: 0 -2976px;
  }
  .ggy,
  .ggy_flag {
    background-position: 0 -3008px;
  }
  .gha,
  .gha_flag {
    background-position: 0 -3040px;
  }
  .gib,
  .gib_flag {
    background-position: 0 -3072px;
  }
  .grl,
  .grl_flag {
    background-position: 0 -3104px;
  }
  .gmb,
  .gmb_flag {
    background-position: 0 -3136px;
  }
  .gin,
  .gin_flag {
    background-position: 0 -3168px;
  }
  .glp,
  .glp_flag {
    background-position: 0 -3200px;
  }
  .gnq,
  .gnq_flag {
    background-position: 0 -3232px;
  }
  .grc,
  .grc_flag {
    background-position: 0 -3264px;
  }
  .gtm,
  .gtm_flag {
    background-position: 0 -3296px;
  }
  .gum,
  .gum_flag {
    background-position: 0 -3328px;
  }
  .gnb,
  .gnb_flag {
    background-position: 0 -3360px;
  }
  .guy,
  .guy_flag {
    background-position: 0 -3392px;
  }
  .hkg,
  .hkg_flag {
    background-position: 0 -3424px;
  }
  .hnd,
  .hnd_flag {
    background-position: 0 -3456px;
  }
  .hrv,
  .hrv_flag {
    background-position: 0 -3488px;
  }
  .hti,
  .hti_flag {
    background-position: 0 -3520px;
  }
  .hun,
  .hun_flag {
    background-position: 0 -3552px;
  }
  // .idn, .idn_flag (Indonesia) and .mco, .mco_flag(Monaco) flags are similar; as a result use the same sprite.
  .idn,
  .idn_flag {
    background-position: 0 -3584px;
  }
  .mco,
  .mco_flag {
    background-position: 0 -3584px;
  }
  .irl,
  .irl_flag {
    background-position: 0 -3616px;
  }
  .isr,
  .isr_flag {
    background-position: 0 -3648px;
  }
  .imn,
  .imn_flag {
    background-position: 0 -3680px;
  }
  .ind,
  .ind_flag {
    background-position: 0 -3712px;
  }
  .irq,
  .irq_flag {
    background-position: 0 -3744px;
  }
  .irn,
  .irn_flag {
    background-position: 0 -3776px;
  }
  .isl,
  .isl_flag {
    background-position: 0 -3808px;
  }
  .ita,
  .ita_flag {
    background-position: 0 -3840px;
  }
  .jey,
  .jey_flag {
    background-position: 0 -3872px;
  }
  .jam,
  .jam_flag {
    background-position: 0 -3904px;
  }
  .jor,
  .jor_flag {
    background-position: 0 -3936px;
  }
  .jpn,
  .jpn_flag {
    background-position: 0 -3968px;
  }
  .ken,
  .ken_flag {
    background-position: 0 -4000px;
  }
  .kgz,
  .kgz_flag {
    background-position: 0 -4032px;
  }
  .khm,
  .khm_flag {
    background-position: 0 -4064px;
  }
  .kir,
  .kir_flag {
    background-position: 0 -4096px;
  }
  .com,
  .com_flag {
    background-position: 0 -4128px;
  }
  .kna,
  .kna_flag {
    background-position: 0 -4160px;
  }
  .prk,
  .prk_flag {
    background-position: 0 -4192px;
  }
  .kor,
  .kor_flag {
    background-position: 0 -4224px;
  }
  .kwt,
  .kwt_flag {
    background-position: 0 -4256px;
  }
  .cym,
  .cym_flag {
    background-position: 0 -4288px;
  }
  .kaz,
  .kaz_flag {
    background-position: 0 -4320px;
  }
  .lao,
  .lao_flag {
    background-position: 0 -4352px;
  }
  .lbn,
  .lbn_flag {
    background-position: 0 -4384px;
  }
  .lca,
  .lca_flag {
    background-position: 0 -4416px;
  }
  .lie,
  .lie_flag {
    background-position: 0 -4448px;
  }
  .lka,
  .lka_flag {
    background-position: 0 -4480px;
  }
  .lbr,
  .lbr_flag {
    background-position: 0 -4512px;
  }
  .lso,
  .lso_flag {
    background-position: 0 -4544px;
  }
  .ltu,
  .ltu_flag {
    background-position: 0 -4576px;
  }
  .lux,
  .lux_flag {
    background-position: 0 -4608px;
  }
  .lva,
  .lva_flag {
    background-position: 0 -4640px;
  }
  .lby,
  .lby_flag {
    background-position: 0 -4672px;
  }
  .mar,
  .mar_flag {
    background-position: 0 -4704px;
  }
  .mda,
  .mda_flag {
    background-position: 0 -4736px;
  }
  .mne,
  .mne_flag {
    background-position: 0 -4768px;
  }
  .mdg,
  .mdg_flag {
    background-position: 0 -4800px;
  }
  .mhl,
  .mhl_flag {
    background-position: 0 -4832px;
  }
  .mkd,
  .mkd_flag {
    background-position: 0 -4864px;
  }
  .mli,
  .mli_flag {
    background-position: 0 -4896px;
  }
  .mmr,
  .mmr_flag {
    background-position: 0 -4928px;
  }
  .mng,
  .mng_flag {
    background-position: 0 -4960px;
  }
  .mac,
  .mac_flag {
    background-position: 0 -4992px;
  }
  .mtq,
  .mtq_flag {
    background-position: 0 -5024px;
  }
  .mrt,
  .mrt_flag {
    background-position: 0 -5056px;
  }
  .msr,
  .msr_flag {
    background-position: 0 -5088px;
  }
  .mlt,
  .mlt_flag {
    background-position: 0 -5120px;
  }
  .mus,
  .mus_flag {
    background-position: 0 -5152px;
  }
  .mdv,
  .mdv_flag {
    background-position: 0 -5184px;
  }
  .mwi,
  .mwi_flag {
    background-position: 0 -5216px;
  }
  .mex,
  .mex_flag {
    background-position: 0 -5248px;
  }
  .mys,
  .mys_flag {
    background-position: 0 -5280px;
  }
  .moz,
  .moz_flag {
    background-position: 0 -5312px;
  }
  .nam,
  .nam_flag {
    background-position: 0 -5344px;
  }
  .ncl,
  .ncl_flag {
    background-position: 0 -5376px;
  }
  .ner,
  .ner_flag {
    background-position: 0 -5408px;
  }
  .nga,
  .nga_flag {
    background-position: 0 -5440px;
  }
  .nic,
  .nic_flag {
    background-position: 0 -5472px;
  }
  .nld,
  .nld_flag {
    background-position: 0 -5504px;
  }
  .bes,
  .bes_flag {
    background-position: 0 -5504px;
  }
  .nor,
  .nor_flag {
    background-position: 0 -5536px;
  }
  .bvt,
  .bvt_flag {
    background-position: 0 -5536px;
  }
  .atn,
  .atn_flag {
    background-position: 0 -5536px;
  }
  .sjm,
  .sjm_flag {
    background-position: 0 -5536px;
  }
  .npl,
  .npl_flag {
    background-position: 0 -5568px;
  }
  .nru,
  .nru_flag {
    background-position: 0 -5600px;
  }
  .nzl,
  .nzl_flag {
    background-position: 0 -5632px;
  }
  .omn,
  .omn_flag {
    background-position: 0 -5664px;
  }
  .pan,
  .pan_flag {
    background-position: 0 -5696px;
  }
  .per,
  .per_flag {
    background-position: 0 -5728px;
  }
  .pyf,
  .pyf_flag {
    background-position: 0 -5760px;
  }
  .png,
  .png_flag {
    background-position: 0 -5792px;
  }
  .phl,
  .phl_flag {
    background-position: 0 -5824px;
  }
  .pak,
  .pak_flag {
    background-position: 0 -5856px;
  }
  .pol,
  .pol_flag {
    background-position: 0 -5888px;
  }
  .pri,
  .pri_flag {
    background-position: 0 -5920px;
  }
  .pse,
  .pse_flag {
    background-position: 0 -5952px;
  }
  .prt,
  .prt_flag {
    background-position: 0 -5984px;
  }
  .plw,
  .plw_flag {
    background-position: 0 -6016px;
  }
  .pry,
  .pry_flag {
    background-position: 0 -6048px;
  }
  .qat,
  .qat_flag {
    background-position: 0 -6080px;
  }
  .reu,
  .reu_flag {
    background-position: 0 -6112px;
  }
  .rou,
  .rou_flag {
    background-position: 0 -6144px;
  }
  .srb,
  .srb_flag {
    background-position: 0 -6176px;
  }
  .rus,
  .rus_flag {
    background-position: 0 -6208px;
  }
  .rwa,
  .rwa_flag {
    background-position: 0 -6240px;
  }
  .sau,
  .sau_flag {
    background-position: 0 -6272px;
  }
  .slb,
  .slb_flag {
    background-position: 0 -6304px;
  }
  .syc,
  .syc_flag {
    background-position: 0 -6336px;
  }
  .sdn,
  .sdn_flag {
    background-position: 0 -6368px;
  }
  .swe,
  .swe_flag {
    background-position: 0 -6400px;
  }
  .sgp,
  .sgp_flag {
    background-position: 0 -6432px;
  }
  .svn,
  .svn_flag {
    background-position: 0 -6464px;
  }
  .svk,
  .svk_flag {
    background-position: 0 -6496px;
  }
  .sle,
  .sle_flag {
    background-position: 0 -6528px;
  }
  .smr,
  .smr_flag {
    background-position: 0 -6560px;
  }
  .sen,
  .sen_flag {
    background-position: 0 -6592px;
  }
  .som,
  .som_flag {
    background-position: 0 -6624px;
  }
  .sur,
  .sur_flag {
    background-position: 0 -6656px;
  }
  .stp,
  .stp_flag {
    background-position: 0 -6688px;
  }
  .slv,
  .slv_flag {
    background-position: 0 -6720px;
  }
  .syr,
  .syr_flag {
    background-position: 0 -6752px;
  }
  .swz,
  .swz_flag {
    background-position: 0 -6784px;
  }
  .tca,
  .tca_flag {
    background-position: 0 -6816px;
  }
  .tcd,
  .tcd_flag {
    background-position: 0 -6848px;
  }
  .tgo,
  .tgo_flag {
    background-position: 0 -6880px;
  }
  .tha,
  .tha_flag {
    background-position: 0 -6912px;
  }
  .tjk,
  .tjk_flag {
    background-position: 0 -6944px;
  }
  .tls,
  .tls_flag {
    background-position: 0 -6976px;
  }
  .tkm,
  .tkm_flag {
    background-position: 0 -7008px;
  }
  .tun,
  .tun_flag {
    background-position: 0 -7040px;
  }
  .ton,
  .ton_flag {
    background-position: 0 -7072px;
  }
  .tur,
  .tur_flag {
    background-position: 0 -7104px;
  }
  .tto,
  .tto_flag {
    background-position: 0 -7136px;
  }
  .tuv,
  .tuv_flag {
    background-position: 0 -7168px;
  }
  .twn,
  .twn_flag {
    background-position: 0 -7200px;
  }
  .tza,
  .tza_flag {
    background-position: 0 -7232px;
  }
  .ukr,
  .ukr_flag {
    background-position: 0 -7264px;
  }
  .uga,
  .uga_flag {
    background-position: 0 -7296px;
  }
  .usa,
  .usa_flag {
    background-position: 0 -7328px;
  }
  .ury,
  .ury_flag {
    background-position: 0 -7360px;
  }
  .uzb,
  .uzb_flag {
    background-position: 0 -7392px;
  }
  .vat,
  .vat_flag {
    background-position: 0 -7424px;
  }
  .vct,
  .vct_flag {
    background-position: 0 -7456px;
  }
  .ven,
  .ven_flag {
    background-position: 0 -7488px;
  }
  .vgb,
  .vgb_flag {
    background-position: 0 -7520px;
  }
  .vir,
  .vir_flag {
    background-position: 0 -7552px;
  }
  .vnm,
  .vnm_flag {
    background-position: 0 -7584px;
  }
  .vut,
  .vut_flag {
    background-position: 0 -7616px;
  }
  .wsm,
  .wsm_flag {
    background-position: 0 -7648px;
  }
  .yem,
  .yem_flag {
    background-position: 0 -7680px;
  }
  .zaf,
  .zaf_flag {
    background-position: 0 -7712px;
  }
  .zmb,
  .zmb_flag {
    background-position: 0 -7744px;
  }
  .zwe,
  .zwe_flag {
    background-position: 0 -7776px;
  }
  .sxm,
  .sxm_flag {
    background-position: 0 -7808px;
  }
  .cuw,
  .cuw_flag {
    background-position: 0 -7840px;
  }
  .ssd,
  .ssd_flag {
    background-position: 0 -7872px;
  }
  .niu,
  .niu_flag {
    background-position: 0 -7904px;
  }
} // .f32
