// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved

// Configuration & bootstrapping for:
// - FS Next components
// - Material components (as built-on by FS Next)
// - Custom components that use FS Next theming system.

// Design system
@use 'fs-next/main' as fsn-main;
@use 'fs-next/material/material.theme' as themes;
@use 'fs-next/material/material-compat' as mat-main;

// Custom components
@use 'emission-calculator/components/zero-emissions/zero-emissions.component-theme' as zero-emission;
@use 'emission-calculator/components/emission-estimate-display/emission-estimate-display.component-theme' as
  emission-estimate-display;

@include mat-main.core;
@include fsn-main.core;

:root {
  // Design system
  @include fsn-main.output-global-tokens();
  @include fsn-main.output-theme-css-vars('light');
  @include mat-main.all-component-themes(themes.$main);
  @include fsn-main.all-component-themes(themes.$main);
  @include fsn-main.special-case-component-themes();

  .fsn-dark-mode {
    @include fsn-main.output-theme-css-vars('dark');
  }

  // Custom components
  @include zero-emission.theme(themes.$main);
  @include emission-estimate-display.theme(themes.$main);
}

// Scoped themes for special parts of the app.
@mixin all-color($theme) {
  // Design system
  @include mat-main.all-color($theme);
  @include fsn-main.all-color($theme);

  // Custom components
  @include zero-emission.color($theme);
  @include emission-estimate-display.color($theme);
}

.theme-biodiversity-blue {
  @include all-color(themes.$biodiversity-blue);
}

.theme-green-tea {
  @include all-color(themes.$green-tea);
}

.theme-orchid {
  @include all-color(themes.$orchid);
}

.theme-pink {
  @include all-color(themes.$pink);
}

.theme-gray {
  @include all-color(themes.$gray);
}

.theme-guidance-blue {
  @include all-color(themes.$guidance-blue);
}

.theme-recommendation-aqua {
  @include all-color(themes.$recommendation-aqua);
}

.theme-recommendation-blue {
  @include all-color(themes.$recommendation-blue);
}

.theme-recommendation-cherry {
  @include all-color(themes.$recommendation-cherry);
}

.theme-meadow {
  @include all-color(themes.$meadow);
}

// TODO: Separate workarounds from actual target styles and move the workarounds into this imported stylesheet.
@import 'fs-next-legacy-workarounds';
@include mat-main.overrides;
