// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

@import 'variables';

.area-chart,
.bar-chart,
.stacked-bar-chart,
.doughnut-chart,
.horizontal-bar-chart,
.line-chart,
.linked-scatter-chart,
.map-chart,
.radial-chart,
.scatter-chart {
  height: inherit;
  width: inherit;
  min-height: inherit;
  position: relative;

  svg {
    fill: $text-color;
  }

  text {
    font-size: 14px;
  }

  g.title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }

  g.title.narrow {
    font-size: 14px;
  }

  .axis {
    .label {
      fill: $primary;
      font-weight: bold;
    }
  }

  .axis path,
  .axis line {
    shape-rendering: crispEdges;
  }

  .legend text,
  .legend circle {
    cursor: pointer;

    &:hover {
      font-weight: bold;
    }
  }

  div.tooltip {
    position: fixed;
    pointer-events: none;
  }

  div.tooltip:not(.mat-mdc-tooltip-panel) {
    padding: 1rem;
    background: #ffffff;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.1);
    font-size: 1em;
  }

  div.tooltip.mat-mdc-tooltip-panel {
    position: absolute;
  }

  .mdc-tooltip {
    background: $primary-color;
    color: white;
  }
}

app-d3-area-chart,
app-d3-bar-chart,
app-d3-stacked-bar-chart,
app-d3-doughnut-chart,
app-d3-horizontal-bar-chart,
app-d3-line-chart,
app-d3-scatter-chart,
app-d3-linked-scatter-chart,
app-d3-map-chart,
app-d3-radial-chart {
  min-height: inherit;

  .chart {
    width: inherit;

    min-height: inherit;

    .actions {
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: baseline;

      &::before {
        content: 'DOWNLOAD';
        padding-right: 1em;
      }

      .btn {
        padding: 0px;
        padding-right: 1em;
      }
    }

    &:not(:hover) .actions {
      display: none;
    }
  }

  &.no-actions .chart {
    &:hover .actions {
      display: none;
    }
  }

  div.loading {
    display: flex;
    justify-content: center;
    font-size: bold;
  }

  div.loading svg {
    display: none;
  }
}
