// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

@import 'bootswatch/dist/materia/variables';

$text-color: rgba(black, 0.733); //#444 on white
$text-color-secondary: rgba(black, 0.698); //#4d4d4d on white
$text-color-tertiary: rgba(black, 0.6); //#666 on white
$primary-color: #412fd5;
$primary-color-lightest: #ebedff;

$light-gray-opacity: rgba(black, 0.0666); //#eee on white

//USED IN BOOTSWATCH
$primary: $primary-color;
$input-focus-box-shadow: inset 0 -2px 0 $primary-color;

$dark: #4d4d4d;
$darker: #272727;
$whitesmoke: #f5f5f5;
$gray: #616161;
$lightgray: #d1c9c9;
$purple: #840093;
$blue: #41c5ff;
$red: #ff5268;
$yellow: #d3df31;
$teal: #81cec5;
$pink: #ffaac3;
$orange: #fa5c03;
$cream: rgba(209, 201, 201, 0.1);
$refinitiv-blue: #001efe;
$text-muted: $lightgray !default;

// Colors for Risk Mitigation
$risk-red: $red;
$risk-orange: #fd7e14;
$risk-yellow: $yellow;
$risk-blue: $blue;
$risk-green: #4caf50;

// Backport of FSNext colors for legacy components
$text-dark: #353a3c;
$text-light: #656e72;
$background-grey: #fbfbfb;
$darkest-green: #1f3312;
$dark-green: #065c42;
$green: #51af82;
$light-green: #06d18b;
$lightest-green: #eef8f1;

$black: rgba(black, 0.87);
$slaves: rgb(132, 0, 147);
$ghg_emissions: rgb(39, 211, 168);
$biodiversity: rgba(255, 82, 88);
$land: rgb(246, 217, 71);
$water: rgb(56, 174, 247);
$tiva: rgb(255, 170, 195);

$success: $teal;
$info: $primary;
$warning: $yellow;
$danger: $red;

$table-accent-bg: $cream;

$flags-images-path: '/assets/images';

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1400px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1340px,
);

$card-border-width: 1px;
$card-border-color: rgba(0, 0, 0, 0.125);
