@use 'sass:map';
@use '@angular/material' as mat;

@function get-prop($config, $level, $prop) {
  @return map.get(map.get($config, $level), $prop);
}

@mixin font-prop($config, $level, $prop) {
  $value: get-prop($config, $level, $prop);
  @if $value != null {
    #{$prop}: #{$value};
  }
}

// TODO: Should use "bolder" etc. for relative weights
$bold-font-weight: 510;

// Just like the mat.typography-level, but emits extra props.
@mixin typography-level($config, $level, $variant: null) {
  @include font-prop($config, $level, font-size);
  @include font-prop($config, $level, font-weight);
  @include font-prop($config, $level, line-height);
  @include font-prop($config, $level, font-family);
  @include font-prop($config, $level, font-variant);
  @include font-prop($config, $level, font-style);

  @if $variant == bold {
    font-weight: $bold-font-weight;
  }
}

// Make the typography levels available as native HTML tags, where possible. For
// other cases, emit styles.
@mixin typography-hierarchy($fs-fonts, $cls, $prefix) {
  body#{$cls},
  body #{$cls} {
    // Undo _reboot
    letter-spacing: normal;
    // Allow Chrome to use subpixel AA
    -webkit-font-smoothing: auto;
    // Make Chrome render text as the foundry intended
    text-rendering: geometricPrecision;
  }

  // Headers

  #{$cls} h1,
  h1#{$cls},
  .#{$prefix}headline-large {
    @include typography-level($fs-fonts, 'Headline/Large');
    margin: 0;
  }
  #{$cls} h2,
  h2#{$cls},
  .#{$prefix}headline-medium {
    @include typography-level($fs-fonts, 'Headline/Medium');
    margin: 0;
  }
  #{$cls} h3,
  h3#{$cls},
  .#{$prefix}headline-small {
    @include typography-level($fs-fonts, 'Headline/Small');
    margin: 0;
  }
  #{$cls} h4,
  h4#{$cls},
  .#{$prefix}headline-x-small {
    @include typography-level($fs-fonts, 'Headline/X-Small');
    margin: 0;
  }

  // Body

  #{$cls} p,
  p#{$cls},
  .#{$prefix}body-medium {
    @include typography-level($fs-fonts, 'Body/Medium');
  }
  #{$cls} small,
  small#{$cls},
  .#{$prefix}body-small {
    @include typography-level($fs-fonts, 'Body/Small');
  }
  #{$cls} small > small,
  small#{$cls} > small,
  .#{$prefix}body-small > small,
  .#{$prefix}body-tiny {
    @include typography-level($fs-fonts, 'Body/Tiny');
  }

  // Common variants. TODO: Move these to fs-next/builtins/

  #{$cls} strong,
  strong#{$cls},
  .#{$prefix}bold {
    font-weight: 510;
  }

  #{$cls} em,
  em#{$cls},
  .#{$prefix}italic {
    font-style: italic;
  }

  #{$cls} a,
  a#{$cls},
  // Increase specificity by using attribute match, so that link styles tend to override the containers they're placed in.
  .#{$prefix}link[class~='#{$prefix}link'] {
    &,
    &:hover,
    // :not([href]):not([class]) is a workaround for legacy styles from _reboot.scss
    &:not([href]):not([class]) {
      text-decoration: underline;
    }
    @include font-prop($fs-fonts, 'Body/Medium', font-weight);
    @include font-prop($fs-fonts, 'Body/Medium', font-style);
  }

  // Special variants

  #{$cls} aside,
  aside#{$cls},
  .#{$prefix}aside {
    @include typography-level($fs-fonts, 'Body/Aside');
    a {
      @include font-prop($fs-fonts, 'Body/Medium', font-weight);
      @include font-prop($fs-fonts, 'Body/Medium', font-style);
      text-decoration: underline;
    }
  }

  // Data

  #{$cls}.data h1,
  #{$cls} .data h1,
  #{$cls} h1.data,
  h1#{$cls}.data,
  .#{$prefix}data-large {
    @include typography-level($fs-fonts, 'Data/Large');
  }
  #{$cls} .data h2,
  #{$cls} h2.data,
  h2#{$cls}.data,
  .#{$prefix}data-medium {
    @include typography-level($fs-fonts, 'Data/Medium');
  }
  #{$cls}.data h3,
  #{$cls} .data h3,
  #{$cls} h3.data,
  h3#{$cls}.data,
  #{$cls}.data h4,
  #{$cls} .data h4,
  #{$cls} h4.data,
  h4#{$cls}.data,
  .#{$prefix}data-label {
    @include typography-level($fs-fonts, 'Data/Label');
  }
  #{$cls}.data,
  #{$cls} .data,
  #{$cls}.data p,
  #{$cls} .data p,
  #{$cls} p.data,
  p#{$cls}.data,
  .#{$prefix}data-small {
    @include typography-level($fs-fonts, 'Data/Small');
  }
  #{$cls}.data em,
  #{$cls} .data em,
  #{$cls} em.data,
  em#{$cls}.data,
  .#{$prefix}data-small-alt {
    @include typography-level($fs-fonts, 'Data/Small Alt');
  }
}

@mixin emit-var($prop, $prefix, $theme, $level) {
  $config: mat.get-typography-config($theme);
  $value: get-prop($config, $level, $prop);
  @if $value != null {
    --#{$prefix}-#{$prop}: #{$value};
  }
}

// Emits theme variables like:
//
//     --prefix-font-size: 1rem;
//
// Use this in your foo-component.theme.scss
@mixin emit-vars($prefix, $theme, $level) {
  @include emit-var(font-size, $prefix, $theme, $level);
  @include emit-var(font-family, $prefix, $theme, $level);
  @include emit-var(font-weight, $prefix, $theme, $level);
  @include emit-var(letter-spacing, $prefix, $theme, $level);
  @include emit-var(font-variant, $prefix, $theme, $level);
  @include emit-var(font-style, $prefix, $theme, $level);
  @include emit-var(line-height, $prefix, $theme, $level);
}

// Emits style properties like:
//
//    font-size: var(--prefix-font-size);
//
// Use this in your foo-component.scss
@mixin emit-level($prefix) {
  font-size: var(--#{$prefix}-font-size);
  font-family: var(--#{$prefix}-font-family);
  font-weight: var(--#{$prefix}-font-weight);
  letter-spacing: var(--#{$prefix}-letter-spacing);
  font-variant: var(--#{$prefix}-font-variant);
  font-style: var(--#{$prefix}-font-style);
  line-height: var(--#{$prefix}-line-height);
}
